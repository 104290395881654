import styled from "styled-components";

export const Styles = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22.5px;

    .group-btn {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    h3 {
      color: var(--main-color);
      font-size: 28px;
      font-family: "Inter";
    }

    button {
      width: 232px;
      background-color: var(--main-color);
      color: #ffffff;
      padding: 6.5px 20px;
      border: none;
      border-radius: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      font-family: "Inter";

      &.btn-refresh {
        width: 100px;
        display: block;
        background-color: #67a8ff;

        p {
          padding-left: 0;
        }
      }

      p {
        font-size: 16px;
        font-weight: 600;
        padding-left: 10px;
      }

      > svg {
        margin-left: 10px;
      }
    }
  }

  .table-wrapper {
    .ag-header-viewport {
      border-top: 1px solid #bcbcbc;
    }

    .ag-root-wrapper {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .ag-cell {
      display: flex;
    }

    .ant-picker-input {
      input {
        font-size: 15px;
        font-family: "Lato";
        &::placeholder {
          font-family: "Noto Sans JP", sans-serif;
          color: rgb(185, 185, 185);
        }
      }
    }

    .order-information-date {
      .order-date {
        transform: translateY(16px);
      }
    }

    .return-order-date {
      display: flex;
      /* transform: translate(-18px, -15px); */
      transform: translateY(-15px);

      span {
        color: #ff0000;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        border: 1px solid #ff0000;
        margin-right: 3px;
        line-height: 17px;
        height: 17px;
        width: 15px;
        transform: translateY(18px);
      }
    }

    .tag {
      width: 66px;
      height: 31px;
      line-height: 20px;
      padding: 5px 0px;
      text-align: center;
      color: #ffffff;
      font-size: 15px;
      font-family: "Inter";

      &.unclaimed,
      &.not_compatible,
      &.not_payment {
        background-color: #ff0000;
      }

      &.billed,
      &.resolved,
      &.paid {
        background-color: #174a84;
      }
    }

    .btn-group {
      gap: 2px;

      .btn-clear {
        margin-right: 0;
        border: none;
        background-color: #ffffff;
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        color: #000000;

        &.btn-active {
          background-color: var(--sub-color);
          border: 1px solid var(--sub-color);
          color: #ffffff;
          padding: 5px;
          border-radius: 64px;
        }
      }

      .btn-search {
        margin-right: 0;
        border: none;
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        margin-right: 6px;
        background-color: var(--main-color);
        color: #ffffff;
        padding: 6.5px 20px;
        border-radius: 64px;

        &-clear {
          background-color: #f2f2f2;
          color: #000;
        }
      }

      .btn-outline {
        border: none;
        background-color: #ffffff;
        font-family: "Inter";
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        width: 120px;

        &.btn-active {
          background-color: var(--sub-color);
          border: 1px solid var(--sub-color);
          color: #ffffff;
          padding: 5px;
          border-radius: 64px;
        }
      }
    }

    .group-button {
      column-gap: 8px;
      row-gap: 2px;
      height: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;

      button {
        background-color: var(--sub-color);
        border: none;
        height: 31px;
        display: flex;
        text-align: center;
        align-items: center;
        color: #ffffff;
        padding: 0 10px;
        font-family: "Inter";
        font-weight: 400;
        font-size: 12px;
        border-radius: 64px;

        &:nth-child(1) {
          padding: 0 9px;
        }

        &:nth-child(2) {
          padding: 0 19px 0 23px;
        }
      }
    }
  }

  .ag-row {
    .cell-disabled {
      color: #c1c1c1;
    }
  }

  .ag-ltr {
    .ag-cell {
      &:last-child {
        display: flex;
      }
    }
  }
  .ant-picker-active-bar {
    display: none;
  }
  .ant-picker-input {
    input {
      font-weight: 400;
      color: black;
    }
  }
  .ant-picker-borderless:after {
    content: "";
    background: #b9b9b9;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 6px;
  }
  /* .ag-cell {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(7),
    &:nth-child(10),
    &:nth-child(11) {
      font-family: "Lato" !important ;
    }
  } */
  .no-padding {
    padding: 0;
    border-bottom: 0;
    width: 100%;
    & > .ant-select-selector {
      padding: 0;
    }
  }
`;
